/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap'); */

/* @font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Book.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-LightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Book.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BookItalic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: 'Circular Std', 'Open Sans', sans-serif;
  font-size: 450;
  /* background-color: #f6f6fa; */
  background-color: #1489c8;
}

.align-items-middle {
  display: flex;
  align-items: center;
}

.align-grid-items-middle {
  display: grid;
  align-items: center;
}

.align-items-bottom {
  display: flex;
  align-items: flex-end;
}

.align-items-bottom-right {
  display: grid;
  align-items: flex-end;
  text-align: right;
}

/* Home */

.home-card {
  border: unset;
  /* margin: 0px 3px; */
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.card-body {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.card-body:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.home-card .card-body {
  border: unset;
}
.home-card-title {
  font-weight: bold;
  font-size: 1.1rem;
}

.home-card-subtitle {
  font-size: 0.9rem;
  font-weight: 300;
}

.mt--1 {
  margin-top: -10px;
}

.form-control:focus {
  border-color: #1489c8;
  box-shadow: unset;
}
.btn.focus,
.btn:focus {
  box-shadow: unset;
}

.fw-light {
  font-weight: 300;
}

.fw-book {
  font-weight: normal;
}

.fw-medium {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

.fw-black {
  font-weight: 900;
}

.pointer {
  cursor: pointer;
}

.detail-label {
  font-size: 0.9rem;
  font-weight: 300;
}

.detail-data {
  font-size: 1.125rem;
  font-weight: normal;
}

input,
select {
  height: 50px;
}

.btn-height {
  /* height: 50px; */
  padding: 13px 0px;
}

.btn-primary {
  background-color: #1489c8;
}
#tab-field a {
  color: #000;
  font-weight: 300;
}

.btn-withdrawal {
  color: #5ba532;
  border: 1px solid #5ba532;
}

.btn-withdrawal:hover,
.btn-withdrawal:active,
.btn-withdrawal:focus {
  border: 1px solid #5ba532;
  color: #5ba532;
}

.btn-deposit {
  color: #fff;
  border: 1px solid #5ba532;
  background-color: #5ba532;
}

.btn-deposit:hover {
  color: #fff;
  border: 1px solid #5ba532;
  background-color: #5ba532;
}

.primary-color {
  color: #5ba532;
}

.primary-bg-color {
  background-color: #5ba532;
}

.color-white {
  color: #fff;
}
.color-black {
  color: #383535;
}

.plain-link {
  text-decoration: none;
}

.plain-link:hover {
  text-decoration: none;
  color: unset;
}

.plain-link:focus-visible,
.plain-link:focus {
  text-decoration: none;
  color: unset;
  box-shadow: unset;
}

.plain-btn:hover,
.plain-btn:active {
  border: unset;
}

.register-card {
  background-color: #e8ecef;
  padding: 25px 15px;
  text-align: center;
  border-radius: 7px;
}

.primary-bg-color:hover {
  background-color: #5ba532;
}

.primary-bg-color-light {
  background-color: #f2f7f3;
}

.secondary-color {
  color: #1489c8;
}

.secondary-bg-color {
  background-color: #1489c8;
}

.padding-left-reset {
  padding-left: unset;
}
.padding-right-reset {
  padding-right: unset;
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.75rem;
}

.fs-9 {
  font-size: 0.6rem;
}

.borderless,
.btn-borderless:hover,
.btn-borderless:active,
.btn-borderless:focus,
.btn-borderless {
  border: unset;
}

/*side menu */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  /* margin-top: 20px; */
  background-color: transparent;
  margin-bottom: 20px;
  border-right: 1px solid #eee;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #fff;
  color: #000;
  border-right: 1px solid #eee;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 400;
  color: #383535;
  font-size: 0.9rem;
}

.sidebar .nav-link .bi {
  font-size: 1.1rem;
}

.sidebar .nav-link.active {
  color: #1489c8;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.sidebar-item {
  margin: 3px 1px;
}

@media (max-width: 768.98px) {
  .sidebar-sticky {
    width: 41%;
  }
}
@media (max-width: 575.98px) {
  .sidebar-sticky {
    width: 60%;
  }
}

/*End sticky */

.name-abbr {
  padding: 9px 16px;
  background-color: #1489c8;
  color: #fff;
  border-radius: 25px;
}

.name-card {
  border-bottom: 2px solid #f6f6f7;
  border-radius: unset;
}

.tab-content {
  background-color: #fff;
  padding: 35px 0px;
}

.navbar .navbar-toggler {
  left: 1rem;
  border-color: transparent;
}

.gift-dash-icon {
  font-size: 3rem;
  padding-right: 10px;
  opacity: 0.3;
}

/* refresh toast notification */
.refresh-toast {
  width: 300px;
  float: right;
  padding: 20px;
  position: fixed;
  right: 2%;
  color: #1489c8;
  font-size: 0.85rem;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  top: 2%;
  background-color: white;
  font-weight: 600;
  cursor: pointer;
  z-index: 1099;
}
